
















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { quillEditor } from 'vue-quill-editor';
import '@mdi/font/css/materialdesignicons.css';

@Component({
  components: {
    quillEditor,
  },
})
export default class WysiwygEditor extends Vue {
  @Prop() value: string;
  @Prop() label: string;
  @Prop({
    default: () => ({
      placeholder: '',
      modules: {
        toolbar: [['bold', 'underline', 'italic', 'link']],
      },
      theme: 'bubble',
    }),
  })
  editorConfig;
  @Prop() bounds: string;
  @Prop() disabled: boolean;

  content = '';
  focused = false;
  dirty = false;

  get editor() {
    return (this.$refs.quillEditor as any).quill;
  }

  @Watch('value')
  valueWatch() {
    this.content = this.value;
  }

  @Watch('content')
  contentWatch() {
    this.$emit('input', this.content.replace(/^<div><br><\/div>$/, ''));
  }

  onEditorBlur() {
    this.focused = false;
  }

  onEditorFocus(event: FocusEvent) {
    this.dirty = true;
    this.focused = true;

    this.$emit('focus', event);
  }

  beforeMount() {
    this.editorConfig.bounds = this.bounds;
    this.valueWatch();
  }
}
