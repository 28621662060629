


































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { LetterModel, TranslationModel } from '@models';
import { Form, FormItem, Input, InputNumber, Button, Divider, Select, Option, Col, Row } from 'element-ui';
import WysiwygEditor from '@/components/WysiwygEditor.vue';
import { Letters } from '@/common/constants';
import VueScrollTo from 'vue-scrollto';
import lodash from 'lodash';

@Component({
  components: {
    'el-form': Form,
    'el-form-item': FormItem,
    'el-col': Col,
    'el-row': Row,
    'el-input': Input,
    'el-input-number': InputNumber,
    'el-button': Button,
    'el-divider': Divider,
    'el-select': Select,
    'el-option': Option,
    WysiwygEditor,
  },
})
export default class TranslationForm extends Vue {
  @Prop({
    default: () => ({
      position: 1,
      letters: [],
    }),
  })
  translation: TranslationModel;
  @Prop() parentSelector: string;

  valid = true;
  headwordRules = [v => !!v || 'Arab/Farsi is required'];
  letterToAdd = '';
  editedLetter: LetterModel = null;
  editedCitationSavedText: string = null;
  editedCitationIndex = -1;
  letters = lodash.sortBy(Letters, 'position');

  get lettersToSelect() {
    return this.letters.filter(
      letter =>
        this.translation == null || this.translation.letters == null || !this.translation.letters.some(l => l.letterKey == letter.key)
    );
  }

  destroyed() {
    this.$store.commit('closeKeyboard');
  }

  switchKeyboard(targetSelector: string) {
    this.$store.commit('switchKeyboard', document.querySelector(`${targetSelector} input`));
  }

  fieldFocused(event: FocusEvent, isKeyboard: boolean) {
    if (isKeyboard) {
      this.$store.commit('newKeyboardFieldFocused', event.target);
    } else {
      this.$store.commit('closeKeyboard');
    }
  }

  buttonFocused() {
    this.$store.commit('closeKeyboard');
  }

  decrement() {
    this.translation.position -= 1;
  }
  increment() {
    this.translation.position += 1;
  }

  getLetterValue(key: string) {
    return this.letters.find(letter => letter.key === key).value;
  }

  addLetter() {
    const newLetter = { letterKey: this.letterToAdd, citations: [''] };
    this.translation.letters.push(newLetter);
    this.translation.letters = lodash.sortBy(
      this.translation.letters,
      letter => this.letters.find(l => l.key === letter.letterKey).position
    );
    this.editedLetter = newLetter;
    this.editedCitationIndex = 0;

    setTimeout(() => {
      VueScrollTo.scrollTo(`.letter.${this.letterToAdd}`, {
        container: '.v-card__text',
        easing: 'ease-in',
        offset: -200,
        force: false,
        cancelable: true,
        x: false,
        y: true,
        onDone: () => {
          const editor = (this.$refs.quillEditor[0] as any).editor;
          editor.setSelection(editor.getLength());
        },
      });

      this.letterToAdd = '';
    });
  }

  addCitation(letter: LetterModel) {
    letter.citations.push('');
    this.editedLetter = letter;
    this.editedCitationIndex = letter.citations.length - 1;

    setTimeout(() => {
      const editor = (this.$refs.quillEditor[0] as any).editor;
      editor.setSelection(editor.getLength());
    });
  }

  editCitation(letter: LetterModel, index: number) {
    this.editedLetter = letter;
    this.editedCitationSavedText = letter.citations[index];
    this.editedCitationIndex = index;

    setTimeout(() => {
      const editor = (this.$refs.quillEditor[0] as any).editor;
      editor.setSelection(editor.getLength());
    });
  }

  cancelCitationEdit() {
    if (this.editedCitationSavedText) {
      this.editedLetter.citations[this.editedCitationIndex] = this.editedCitationSavedText;
    } else {
      this.removeCitation(this.editedLetter, this.editedCitationIndex);
    }

    this.editedLetter = null;
    this.editedCitationSavedText = null;
    this.editedCitationIndex = -1;
  }

  saveCitationChanges() {
    this.editedLetter = null;
    this.editedCitationSavedText = null;
    this.editedCitationIndex = -1;
  }

  removeCitation(letter: LetterModel, index: number) {
    letter.citations.splice(index, 1);

    if (letter.citations.length === 0) {
      this.translation.letters = this.translation.letters.filter(l => l.letterKey !== letter.letterKey);
    }
  }
}
