





























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class ConfirmationDialog extends Vue {
  @Prop() title: string;
  @Prop() text: string;
  @Prop() value: boolean;

  dialogState = false;

  @Watch('value')
  valueWatch() {
    this.dialogState = this.value;
  }

  @Watch('content')
  contentWatch() {
    this.$emit('input', this.dialogState);
  }

  onAccept(accept: boolean) {
    this.$emit('close', accept);
  }
}
